import { INavbar } from 'apps/agent-dashboard/helpers/renderNavbar';
import styled, { css } from 'styled-components';

import { useState } from 'react';

import Link from 'next/link';

import { AvatarWrapper, Icon } from '@hl-portals/ui';

import { media, slideInFromTop } from '@hl-portals/helpers';

import { useUserEvents } from '@hl-portals/hooks';

import { NavbarHover, NavbarItem, NavbarList } from '../Navbar/styles';

interface INavbarDropdown {
  isOpen: boolean;
}

export const NavbarDropdownWrapper = styled.nav<INavbarDropdown>`
  position: absolute;
  top: 61px;
  height: ${({ isOpen }) => (isOpen ? 'calc(100vh - 61px)' : '0')};
  overflow: hidden;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: opacity 0.3s ease-out;
  background: #fff;
  z-index: 5;
  box-sizing: border-box;

  ${media.desktop`
    left: -20px;
    border-radius: 6px;
    height: auto;
    overflow: visible;
    display: ${({ isOpen }: INavbarDropdown) => (isOpen ? 'block' : 'none')};
    animation: 0.3s ease 0s 1 normal ${slideInFromTop};
    left: auto;
    right: 0;
    top: calc(100% - 5px);
  `}

  ${NavbarList} {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    box-sizing: border-box;
    background: #fff;
    border-radius: 6px;
    padding: 0;
    margin: 0;
    width: 100vw;
    ${media.desktop`
      width: 280px;
      border: 1px solid rgb(238, 241, 247);
      box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 8px;
    `}
    ${NavbarItem} {
      width: 100%;
      display: block;
      line-height: normal;
      margin: 0px;
      padding: 12px 20px;
    }
  }
`;

const ArrowDown = css`
  &::before {
    content: '';
    display: block;
    width: 6px;
    height: 1px;
    background-color: #666;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: rotate(45deg) translateY(-50%);
  }
  &::after {
    content: '';
    display: block;
    width: 6px;
    height: 1px;
    background-color: #666;
    position: absolute;
    top: 50%;
    right: 4px;
    transform: rotate(-45deg) translateY(-50%);
  }
`;

export const DropdownButton = styled.span<{ isElite: boolean }>`
  ${({ theme, isElite }) => css`
    cursor: pointer;
    position: relative;
    padding-right: 20px !important;
    margin-right: 15px;
    ${ArrowDown}
    ${AvatarWrapper} {
      display: none;
    }
    ${media.desktop`
      ${AvatarWrapper} {
        display: block;
      }
      > span { display: none}
    `}

    &:focus {
      outline: none;
    }

    svg {
      display: none;
      fill: ${isElite ? theme.colors.silver : theme.colors.coolGray3};
      width: 20px;
      height: 20px;
      ${media.desktop`
        display: block;
      `}
    }
    &:hover {
      svg {
        fill: ${theme.colors.electricBlue};
      }
      ::before,
      ::after {
        background-color: ${theme.colors.electricBlue};
      }
    }
  `}
`;

const ArrowDownComponent = styled.div<{ isOpen?: boolean }>`
  width: 12px;
  height: 12px;
  position: relative;
  trasform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0deg')});
  transition: rotate 300ms ease-in-out;
  ${ArrowDown};
`;

const DropdownInnerButton = styled(NavbarItem)<{ isOpen: boolean }>`
  width: 100%;
  padding-right: 80px;
  display: flex !important;
  justify-content: space-between;
  cursor: pointer;
  font-weight: ${({ isOpen }) => (isOpen ? 600 : 300)};

  ${ArrowDownComponent} {
    transform-origin: center;
    transform: rotate(${({ isOpen }) => (isOpen ? '-180deg' : '0deg')})
      translate(3px);
    transition: all 300ms ease-in-out;
  }

  &:hover ${NavbarHover} {
    background-color: #f5f6f9;
    width: calc(100% - 25px);
    left: 13px;
  }

  @media (hover: none) and (pointer: coarse) {
    &:hover ${NavbarHover} {
      background-color: transparent;
    }
  }
`;

const DropdownInnerList = styled.ul<{ isOpen: boolean }>`
  width: 100%;
  ${({ isOpen }) => `max-height: ${isOpen ? '100vh' : '0'}`};
  overflow: hidden;
  transition: max-height 300ms ease-in-out;
  box-sizing: border-box;

  ${NavbarItem} {
    &:hover ${NavbarHover} {
      background-color: #f5f6f9;
      width: calc(100% - 40px);
      left: 20px;
    }
    @media (hover: none) and (pointer: coarse) {
      &:hover ${NavbarHover} {
        background-color: transparent;
      }
    }
  }
`;

export const NavbarDropdownInner = ({ item }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const { recordUserEvent } = useUserEvents();

  const handleOnClickNav = (_item: INavbar) => {
    if (_item.key && _item.type !== 'dropdown') {
      recordUserEvent({
        source: 'agent_dashboard',
        event_action: 'agent_portal_nav_menu',
        result: {
          action: 'click',
          target: _item.key,
        },
        includeSource: false,
      });
    }
  };

  return (
    <>
      <DropdownInnerButton
        onClick={() => setIsOpen((prev) => !prev)}
        isOpen={isOpen}
      >
        <NavbarHover />
        <a>{item.text}</a>
        <ArrowDownComponent />
      </DropdownInnerButton>
      <DropdownInnerList isOpen={isOpen}>
        {item.items
          .filter((i) => !i.hide)
          .map((_item) => (
            <Link
              href={
                typeof _item.url === 'function' ? _item.url() : _item.url ?? ''
              }
              passHref
              key={_item.id}
            >
              <a
                onClick={() => handleOnClickNav(_item)}
                target={_item.openInNewTab ? `_target${_item.id}` : '_self'}
                style={{ width: '100%' }}
              >
                <NavbarItem
                  display={_item.icon ? 'flex !important' : 'block'}
                  alignItems="center"
                  justifyContent="flex-start !important"
                  gap={_item.icon ? '10px' : 'none'}
                  isLink={_item.type === 'link'}
                  style={{ paddingLeft: 40 }}
                >
                  {_item.icon && <Icon type={_item.icon} />}
                  <NavbarHover />
                  <span>{_item.text}</span>
                </NavbarItem>
              </a>
            </Link>
          ))}
      </DropdownInnerList>
    </>
  );
};

export const NavbarProfile = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.darkBlue};
  padding: 16px 24px;
  overflow: hidden;
  margin-bottom: 8px;
`;
