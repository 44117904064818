import { CSSObject, css } from 'styled-components';

export const media = {
  large: (first: CSSObject | TemplateStringsArray, ...interpolations) => css`
    @media (min-width: 1280px) {
      ${css(first, ...interpolations)}
    }
  `,
  desktop: (first: CSSObject | TemplateStringsArray, ...interpolations) => css`
    @media (min-width: 960px) {
      ${css(first, ...interpolations)}
    }
  `,
  untilDesktop: (
    first: CSSObject | TemplateStringsArray,
    ...interpolations
  ) => css`
    @media (max-width: 959px) {
      ${css(first, ...interpolations)}
    }
  `,
  tablet: (first: CSSObject | TemplateStringsArray, ...interpolations) => css`
    @media (min-width: 600px) {
      ${css(first, ...interpolations)}
    }
  `,
  untilTablet: (
    first: CSSObject | TemplateStringsArray,
    ...interpolations
  ) => css`
    @media (max-width: 599px) {
      ${css(first, ...interpolations)}
    }
  `,
  phone: (first: CSSObject | TemplateStringsArray, ...interpolations) => css`
    @media (min-width: 320px) {
      ${css(first, ...interpolations)}
    }
  `,
  print: (first: CSSObject | TemplateStringsArray, ...interpolations) => css`
    @media print {
      ${css(first, ...interpolations)}
    }
  `,
  extraSmall: (
    first: CSSObject | TemplateStringsArray,
    ...interpolations
  ) => css`
    @media (min-width: 0px) {
      ${css(first, ...interpolations)}
    }
  `,
  shortPhone: (first: CSSObject | TemplateStringsArray, ...interpolations) => css`
    @media (max-width: 600px) and (max-height: 700px) {
      ${css(first, ...interpolations)}
    }
  `,
};

export const SIZES_BREAKPOINTS = {
  xs: media.extraSmall,
  sm: media.tablet,
  md: media.desktop,
};

export const BREAKPOINT_VALUES = {
  sm: 600,
  md: 960,
  lg: 1280,
};

export type BreakpointSizes = keyof typeof SIZES_BREAKPOINTS;
export type CSSVariants<T> =
  | T
  | {
      [key in BreakpointSizes]?: T;
    };
