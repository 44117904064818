/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { Spinner } from '../../components';
import { useSnapshot } from './hooks';

type SnapshotState = {
  uuid?: string;
  snapshot: Record<any, any>;
  steps?: Record<any, any>;
  leadId?: number;
  update: (data: Record<any, any>) => void;
};

const DEFAULT_STATE = {
  snapshot: {},
  update: () => {},
};

const SnapshotContext = createContext<SnapshotState>({ ...DEFAULT_STATE });

export const SnapshotProvider = ({
  type,
  initialData,
  children,
}: {
  type: string;
  initialData: Record<any, any>;
  children: React.ReactNode;
}) => {
  const [snapshot, setSnapshot] = useState<Record<any, any>>({});
  const [uuid, setUuid] = useState<string>();
  const router = useRouter();

  useEffect(() => {
    if (router.query['uuid'] && router.query['uuid'] !== uuid)
      setUuid(router.query['uuid'] as string);
  }, [router.query['uuid'], uuid]);

  const { fetch, update, create } = useSnapshot({
    onFetchSuccess: ({ data }: { data: any }) => {
      if (data?.data?.attributes?.payload) {
        setSnapshot({
          ...data.data.attributes.payload,
          snapshot_id: data.data.id,
        });
      }
    },
    onCreateSuccess: ({ data }: { data: any }) => {
      if (data?.data?.attributes?.uuid) {
        router.query['uuid'] = data?.data?.attributes?.uuid;
        router.replace({
          pathname: router.pathname,
          query: router.query,
        });
        setUuid(data?.data?.attributes?.uuid);
      }
    },
    onUpdateSuccess: () => {},
  });

  useEffect(() => {
    if (uuid) fetch(uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  useEffect(() => {
    if (!router.query['uuid']) {
      setSnapshot({});

      create({
        type,
        payload: initialData,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query['uuid']]);

  const updateSnapshot = (data: Record<any, any>) => {
    setSnapshot(data);
    update({
      uuid: uuid as string,
      payload: data,
    });
  };

  return (
    <SnapshotContext.Provider
      value={{
        uuid,
        snapshot,
        update: updateSnapshot,
      }}
    >
      {uuid ? children : <Spinner xl mt="200px" />}
    </SnapshotContext.Provider>
  );
};

export const useSnapshotContext = () => {
  const ctx = useContext(SnapshotContext);
  if (!ctx) throw new Error('Missing SnapshotProvider');
  return ctx;
};
