import { PUBLIC_PAGES, TOKENIZED_PAGES } from '@hl-portals/constants';

const isPublicOrTokenizedPage = (pathname) => {
  if (!pathname) return false;

  if (PUBLIC_PAGES.includes(pathname) || TOKENIZED_PAGES.includes(pathname)) {
    return true;
  }

  return PUBLIC_PAGES.some(pattern => {
    // Convert route pattern to regex
    const regexPattern = pattern.replace(
      /\[([^\]]+)\]/g, // Matches anything in square brackets
      '[^/]+' // Replaces with regex to match any characters except /
    );
    const regex = new RegExp(`^${regexPattern}$`);
    return regex.test(pathname);
  }) || TOKENIZED_PAGES.some(pattern => {
    const regexPattern = pattern.replace(/\[([^\]]+)\]/g, '[^/]+');
    const regex = new RegExp(`^${regexPattern}$`);
    return regex.test(pathname);
  });
};

export default isPublicOrTokenizedPage;
