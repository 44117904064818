import { colors } from '@homelight/particle-tokens';

import { useState } from 'react';

import Link from 'next/link';

import { Avatar, Box, Divider, Icon, Paragraph } from '@hl-portals/ui';

import {
  useHandleClickOutside,
  useScreenSize,
  useUserEvents,
} from '@hl-portals/hooks';

import { useUser } from '../../../contexts/Auth';
import { INavbar } from '../../../helpers/renderNavbar';
import { NavbarHover, NavbarItem, NavbarList } from '../Navbar/styles';
import {
  DropdownButton,
  NavbarDropdownInner,
  NavbarDropdownWrapper,
  NavbarProfile,
} from './styles';

type IDropdownUser = {
  firstName: string;
  lastName: string;
  email: string;
};

interface NavbarDropdownProps {
  item: INavbar;
  menuClose?: () => void;
  isElite: boolean;
  user: IDropdownUser;
  alwaysOpen?: boolean;
}

const NavbarDropdown = ({
  item,
  menuClose,
  isElite,
  alwaysOpen,
}: NavbarDropdownProps): React.ReactElement => {
  const { user, featureFlags } = useUser();
  const [state, setState] = useState(0);

  const { isMobile } = useScreenSize();
  const { recordUserEvent } = useUserEvents();

  const navRef = useHandleClickOutside<HTMLElement>({
    onClickOutside: () => setState(0),
  });

  const onOpenDropdown = (id: number): void => {
    setState(id === state ? 0 : id);
  };

  const checkPrograms = ({
    featureFlags: flags,
    somePrograms,
    everyPrograms,
  }) =>
    (typeof everyPrograms === 'undefined' &&
      typeof somePrograms === 'undefined') ||
    somePrograms?.some((program) => Boolean(flags[program])) ||
    everyPrograms?.every((program) => Boolean(flags[program]));

  const onLinkClick = (_item: INavbar) => {
    handleOnClickNav(_item);

    menuClose();
    setState(0);
  };

  const handleOnClickNav = (_item: INavbar) => {
    if (_item.key && _item.type !== 'dropdown') {
      recordUserEvent({
        source: 'agent_dashboard',
        event_action: 'agent_portal_nav_menu',
        result: {
          action: 'click',
          target: _item.key,
        },
        includeSource: false,
      });
    }
  };

  return (
    <>
      {!isMobile && (
        <DropdownButton
          isElite={isElite}
          role="button"
          tabIndex={0}
          onClick={() => onOpenDropdown(item.id)}
          onKeyPress={() => onOpenDropdown(item.id)}
          data-test="navbar-dropdown-button"
        >
          <Icon size={20} type="user" />
          <span>{item.text}</span>
        </DropdownButton>
      )}
      <NavbarDropdownWrapper
        ref={navRef}
        isOpen={alwaysOpen || state === item.id}
      >
        <NavbarList data-test="navbar-dropdown-list">
          <NavbarProfile>
            <Avatar
              size="big"
              type="normal"
              initials={user.first_name[0]}
              mr="10px"
            />
            <Box
              flexDirection="column"
              data-test="navbar-profile-user-name"
              overflowY="hidden"
            >
              <Paragraph
                variant="heading-5"
                color="darkBlue"
                mb="2px"
                truncate
                title={`${user.first_name} ${user.last_name}`}
              >
                {user.first_name} {user.last_name}
              </Paragraph>
              <Paragraph
                variant="text-xsmall"
                color="coolGray2"
                truncate
                title={user.email}
              >
                {user.email}
              </Paragraph>
            </Box>
          </NavbarProfile>
          <Divider margin={'0 20px'} />
          {item?.items
            ?.filter((i) => !i.hide)
            .map((dropdownItem) => {
              if (
                !checkPrograms({
                  featureFlags,
                  somePrograms: dropdownItem.someProgramKey,
                  everyPrograms: dropdownItem.everyProgramKey,
                })
              ) {
                return null;
              }

              if (dropdownItem.type === 'dropdown') {
                return (
                  <NavbarDropdownInner
                    key={dropdownItem.id}
                    item={dropdownItem}
                  />
                );
              }

              return (
                <Link
                  key={dropdownItem.id}
                  href={
                    typeof dropdownItem.url === 'function'
                      ? dropdownItem.url()
                      : dropdownItem.url ?? ''
                  }
                  target={dropdownItem.openInNewTab ? '_target' : '_self'}
                  passHref
                >
                  <a
                    onClick={() => onLinkClick(dropdownItem)}
                    style={{ width: '100%' }}
                  >
                    <NavbarItem
                      key={dropdownItem.id}
                      data-test="navbar-dropdown-item"
                      isLink={dropdownItem.type === 'link'}
                    >
                      {dropdownItem.type === 'link' && (
                        <>
                          <NavbarHover />
                          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                          <span>{dropdownItem.text}</span>
                        </>
                      )}
                    </NavbarItem>
                  </a>
                </Link>
              );
            })}
          <Divider color={'coolGray5'} margin={'8px 20px 0 20px'} />
          <NavbarItem
            data-test="navbar-dropdown-item"
            style={{ margin: '8px 0' }}
            isLink={true}
          >
            <NavbarHover />
            <Link href="/logout" passHref>
              <a
                onClick={() =>
                  onLinkClick({ key: 'sign_out', type: 'link', id: 1 })
                }
                style={{ color: colors.electricBlue }}
              >
                Sign Out
              </a>
            </Link>
          </NavbarItem>
        </NavbarList>
      </NavbarDropdownWrapper>
    </>
  );
};

export default NavbarDropdown;
